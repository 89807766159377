@import '@aurora/shared-client/styles/_variables.pcss';

.lia-rte-content-wrapper {
  :global {
    .mce-content-body {
      min-height: 200px;
    }
  }

  margin: 10px;

  @media (--lia-breakpoint-down-sm) {
    :global {
      .mce-content-body {
        min-height: 100px;
      }
    }
    margin: 5px;
  }
}

.lia-rte-toolbar {
  position: sticky;
  top: 0;
  z-index: 2;
}

.lia-rte-wrapper {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-textarea-border-radius);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);
  overflow: hidden;

  &:focus-within {
    outline: var(--lia-outline-color) solid 1px;
    border-radius: var(--lia-bs-border-radius);
    box-shadow: var(--lia-bs-input-focus-box-shadow);
  }
}

.lia-content-box {
  border-radius: var(--lia-bs-border-radius);
  padding: 7px 10px;
  margin: -7px -10px;

  &:hover {
    cursor: pointer;
    background-color: var(--lia-bs-gray-200);
  }
}

.lia-warning-container {
  margin: 0 0 24px 0;
  text-align: center;

  .lia-warning-emails-list-container {
    background-color: var(--lia-bs-gray-200);
    padding: 24px 0;
  }
  .lia-warning-emails-list {
    margin: 0;
    padding: 0;
    font-size: var(--lia-bs-font-size-sm);
    list-style-type: none;
  }
}

.lia-modal-description {
  display: block;
  order: 2;
  margin-top: 10px;
  width: 100%;
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-normal);
  margin-bottom: 0;
}
