.lia-message > .lia-message {
  margin-top: 5px;
  margin-left: 25px;
}

.lia-message-focus {
  border: 2px solid var(--lia-bs-primary);
  border-radius: var(--lia-bs-border-radius-sm);
}

.lia-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lia-message-scroll-margin {
  scroll-margin-top: 100px;
}
