.MessageDeepLink_lia-message__i0ygZ > .MessageDeepLink_lia-message__i0ygZ {
  margin-top: 0.3125rem;
  margin-left: 1.5625rem;
}

.MessageDeepLink_lia-message-focus__9FX6y {
  border: 2px solid var(--lia-bs-primary);
  border-radius: var(--lia-bs-border-radius-sm);
}

.MessageDeepLink_lia-actions__gEQWK {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MessageDeepLink_lia-message-scroll-margin__veHrk {
  scroll-margin-top: 100px;
}

.UserAvatar_lia-avatar__HFfcv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .UserAvatar_lia-avatar__HFfcv .UserAvatar_lia-avatar-img__CUPwZ {
    border-radius: var(--lia-avatar-border-radius);
    background-color: var(--lia-bs-gray-400);
    overflow: hidden;
    display: block;
    background-size: contain;
    height: 100%;
    width: 100%;
  }

/* Media breakpoints */
/* Breakpoint Down */
/* Breakpoint Up */
/* Panels */
/* Admin */
/* Search */
/* Indents */
/* Quilts */
.SendMembershipInviteModal_lia-rte-content-wrapper__SB5YI .mce-content-body {
      min-height: 12.5rem;
    }
.SendMembershipInviteModal_lia-rte-content-wrapper__SB5YI {

  margin: 0.625rem;
}
@media (max-width: 767.98px) {
.SendMembershipInviteModal_lia-rte-content-wrapper__SB5YI {
    margin: 0.3125rem
}
      .SendMembershipInviteModal_lia-rte-content-wrapper__SB5YI .mce-content-body {
        min-height: 6.25rem;
      }
  }
.SendMembershipInviteModal_lia-rte-toolbar__e35PN {
  position: sticky;
  top: 0;
  z-index: 2;
}
.SendMembershipInviteModal_lia-rte-wrapper__AuiEo {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-textarea-border-radius);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);
  overflow: hidden;
}
.SendMembershipInviteModal_lia-rte-wrapper__AuiEo:focus-within {
    outline: var(--lia-outline-color) solid 1px;
    border-radius: var(--lia-bs-border-radius);
    box-shadow: var(--lia-bs-input-focus-box-shadow);
  }
.SendMembershipInviteModal_lia-content-box__9FsbT {
  border-radius: var(--lia-bs-border-radius);
  padding: 0.4375rem 0.625rem;
  margin: -0.4375rem -0.625rem;
}
.SendMembershipInviteModal_lia-content-box__9FsbT:hover {
    cursor: pointer;
    background-color: var(--lia-bs-gray-200);
  }
.SendMembershipInviteModal_lia-warning-container__ozTBC {
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.SendMembershipInviteModal_lia-warning-container__ozTBC .SendMembershipInviteModal_lia-warning-emails-list-container__bEoEc {
    background-color: var(--lia-bs-gray-200);
    padding: 1.5rem 0;
  }
.SendMembershipInviteModal_lia-warning-container__ozTBC .SendMembershipInviteModal_lia-warning-emails-list__BaF0G {
    margin: 0;
    padding: 0;
    font-size: var(--lia-bs-font-size-sm);
    list-style-type: none;
  }
.SendMembershipInviteModal_lia-modal-description__QA6cY {
  display: block;
  order: 2;
  margin-top: 0.625rem;
  width: 100%;
  font-size: var(--lia-bs-font-size-base);
  font-weight: var(--lia-bs-font-weight-normal);
  margin-bottom: 0;
}

